import { FundOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useContext, useState, useEffect } from "react";
import UserContext from './UserContext';
import { formatNumber, isMobile } from '../utils/utils'
import { IconInfoChartGLByAsset } from './Icons';
import BarChart from './charts/BarChart';


// CHART CONFIGS //

const labelOption = {
	show: isMobile() ? false : true,
	position: 'insideBottom',
	distance: 15,
	align: 'left',
	verticalAlign: 'middle',
	rotate: 90,
	formatter: function (params) {
		return '$ ' + formatNumber(params.value)
	},
	fontSize: 15,
	rich: {
		name: {}
	}
}


const PortfolioGainLossChart = () => {
	const { userContext } = useContext(UserContext);

	const [tradeSummaryXAxis, setTradeSummaryXAxis] = useState([]);
	const [tradeSummarySeries, setTradeSummarySeries] = useState([]);

	useEffect(() => {

		if (userContext != null && userContext.stockTradingBook.length > 0) {

			const stockTradingBook = userContext.stockTradingBook

			// Use a Set to store unique symbols
			const uniqueSymbols = new Set();
			stockTradingBook.forEach((item) => {
				uniqueSymbols.add(item.symbol)
			})

			const buy = []
			const sell = []
			const holdings = []
			uniqueSymbols.forEach((symbol) => {

				// Calculate holdings per symbol
				holdings.push(stockTradingBook.reduce((sum, order) => {
					if (order.symbol === symbol && order.actionType === 'BUY' && order.shares === order.remainingShares) {
						return sum + (order.remainingShares * order.price) + order.fee;
					}
					return sum;
				}, 0))

				// Calculate buy per symbol
				buy.push(stockTradingBook.reduce((sum, order) => {
					if (order.symbol === symbol && order.actionType === 'BUY' && order.shares !== order.remainingShares) {
						return sum + (order.shares - order.remainingShares) * order.price + order.fee;
					}
					return sum;
				}, 0))

				// Calculate sell per symbol
				sell.push(stockTradingBook.reduce((sum, order) => {
					if (order.symbol === symbol && order.actionType === 'SELL') {
						return sum + order.shares * order.price - order.fee + order.totalDividendsPaid
					}
					return sum;
				}, 0))
			})

			// Calculate gain/loss
			const gainLoss = []
			for (var i = 0; i < buy.length; i++) {
				gainLoss.push(sell[i] - buy[i])
			}

			setTradeSummaryXAxis([...uniqueSymbols])
			setTradeSummarySeries([
				{
					name: 'Gain/Loss',
					data: gainLoss
				},
				{
					name: 'Shares Buy Cost',
					legendSelected: false,
					data: buy,
				},
				{
					name: 'Shares Sold Value',
					legendSelected: false,
					data: sell
				},
				{
					name: 'Holdings',
					legendSelected: false,
					data: holdings,
				},
			])
		}
	}, [userContext])


	return (
		<BarChart
			title={<Space><FundOutlined />Gain/Loss By Asset</Space>}
			height='512px'
			series={tradeSummarySeries}
			xAxis={tradeSummaryXAxis}
			labelOptions={labelOption}
			filled
			legend
			currency
			extra={<IconInfoChartGLByAsset />} />
	)
}

export default PortfolioGainLossChart;