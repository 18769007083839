import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { IconInHoldings, IconLoading } from './Icons';
import { Menu, Layout, Image, Button } from 'antd';
import React, { useContext, useEffect, useState } from "react";
import UserContext from './UserContext';
import { getLogoUrl } from '../utils/utils';

const { Sider } = Layout;

const TickerLeftMenu = (props) => {

    const { userContext, delTickerFromWatchlist, doIHold } = useContext(UserContext);
    const [collapsed, setCollapsed] = useState(false)

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)

        // Reload candles to resize width
        props.setCandlesReloadKey(prev => prev + 1)
    }

    //
    const onClick = (e) => {
        props.setCurrItem(e.key);
    }

    const handleDelete = async (e, itemId) => {
        e.stopPropagation(); // Prevent event propagation to parent elements

        if (!props.isDemo) {
            delTickerFromWatchlist(itemId)
            const newItems = props.items.filter((item) => item.key !== itemId)
            props.setItems(newItems);

            // After "setItems(newItems)", update is not fast enough to use "props.items", so we use "newItems"
            if (props.currItem === itemId) {
                if (newItems.length > 0)
                    props.setCurrItem(newItems[0].key)
                else
                    // Set the scenario where the user has not item in his watchlist
                    props.setCurrItem(null)
            }
        }
    }

    useEffect(() => {
        const fetchData = () => {
            if (userContext != null && userContext.watchlist.length > 0) {
                const items = userContext.watchlist.map((tkrUserParam) => ({
                    label: <>{tkrUserParam.ticker} {doIHold(tkrUserParam.ticker) && <IconInHoldings />}</>,
                    key: tkrUserParam.ticker
                }))
                props.setItems(items)
            }
        };
        fetchData()
    }, [userContext])   // Executed only on new object

    useEffect(() => {
        props.setTickerLeftMenuIsLoaded(true)
    })

    if (!userContext && !props.isDemo) {
        return (
            <Sider className='left-menu' width={190}>
                <IconLoading />
            </Sider>
        )
    }

    return (<>
        <Sider ref={props.tourRef} className="sider-left" width={190} collapsed={collapsed} collapsedWidth={58}>
            <div style={{ textAlign: 'right' }}>
                <Button type="text"
                    onClick={toggleCollapsed}
                    style={{ margin: '7px 7px 5px 0' }}
                    icon={collapsed ? <MenuUnfoldOutlined className='button-icon' /> : <MenuFoldOutlined className='button-icon' />} />
            </div>
            <Menu className='left-menu' mode="inline" onClick={onClick} selectedKeys={[props.currItem]}>
                {props.items.map((item) => (
                    <Menu.Item className='left-menu-item' key={item.key} icon={<Image width={17} src={getLogoUrl(item.key)} preview={false} />}>
                        <span style={{ marginLeft: '0.8em' }}>{item.label}</span>
                        <span onClick={(e) => handleDelete(e, item.key)} style={{ cursor: 'pointer', float: "right" }}>&times;</span>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    </>)
}

export default TickerLeftMenu;