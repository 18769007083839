import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MetaTags = ({ children, title, description, keywords }) => {
    const location = useLocation();

    title = title ?? "AI Trading Signals Platform | Just Trade It"
    description = description ?? "AI trading platform designed to give retail traders and investors a competitive edge with cutting-edge artificial intelligence and advanced trading tools."
    keywords = keywords ?? "AI Trading, AI Trading Signals, AI Signals, AI Trading Platform"

    return (
        <Helmet>
            <link rel="canonical" href={`https://justtradeit.co${location.pathname}`} />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            {/* Add your OG meta tags here */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="%PUBLIC_URL%/jti_image_192.png" />
            <meta property="og:url" content="https://justtradeit.co" />
            <meta property="og:type" content="website" />

            {/* X.com */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@jtradeit" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://justtradeit.co/jti_image_192.png" />

            <title>{title}</title>

            {children}
        </Helmet>)
}

export default MetaTags;