import { Layout, Button, Table, Modal, Card } from 'antd';
import { IconLoading, } from '../../components/Icons';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from '../../components/UserContext';
import { getAuditTrail } from '../../services/jti.service';
import { getColumnSearchProps } from '../../utils/CustomizeTable';
import { Link } from 'react-router-dom';
import PageTitle1 from '../../components/PageTitle1';
import { AUDIT_TRAIL_LIMIT } from '../../JTIConst';


// PARAMETERS //

const FILTER_PREFIXES = ["WATCH_", "SIGNAL_"];


const AuditTrail = () => {

    const { userContext } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()
    const [tableData, setTableData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('')
    const [actionFilter, setActionFilter] = useState('')
    const [defaultSelectedFilters, setDfaultSelectedFilters] = useState([])
    const authorSearchRef = useRef(null)
    const tickerSearchRef = useRef(null)

    const showModal = (modalContent) => {
        setModalContent(modalContent)
        setIsModalOpen(true)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const paginationOptions = {
        defaultPageSize: 100,
        showSizeChanger: false,
    }

    const columns = [
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            ...getColumnSearchProps(authorSearchRef, 'author')
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            sorter: (a, b) => renderToString(a.action).localeCompare(renderToString(b.action)),
            filters: actionFilter,
            onFilter: (value, record) => record.action.includes(value),
            defaultFilteredValue: defaultSelectedFilters
        },
        {
            title: 'Ticker',
            width: '10%',
            dataIndex: 'ticker',
            key: 'ticker',
            sorter: (a, b) => renderToString(a.ticker).localeCompare(renderToString(b.ticker)),
            ...getColumnSearchProps(tickerSearchRef, 'ticker'),
            render: (_, record) => <Link to={'../tickeranalysis/' + record.ticker} >{record.ticker}</Link>
        },
        {
            title: 'Data',
            width: '35%',
            ellipsis: {
                showTitle: false,
            },
            dataIndex: 'data',
            key: 'data',
            render: (_, record) => (
                <Button type="link" onClick={() => showModal(<pre>{JSON.stringify(record.data, null, 2)}</pre>)}>
                    {JSON.stringify(record.data, null, 2)}
                </Button>
            )
        }
    ]

    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            const resp = await getAuditTrail(accessToken, AUDIT_TRAIL_LIMIT)
            const auditTrail = resp.data

            const datas = auditTrail.map(entry => ({
                key: entry.id,
                timestamp: entry.timestamp,
                author: entry.author,
                action: entry.action,
                ticker: entry.ticker,
                data: entry.data,
            }))

            // Extracting unique action values for filter
            const uniqueActions = [...new Set(auditTrail.map(entry => entry.action))];

            // Creating filters for action column
            setActionFilter(uniqueActions.map(action => ({
                text: action,
                value: action,
            })))

            // Define default selected filters
            setDfaultSelectedFilters(uniqueActions.filter(item => !FILTER_PREFIXES.some(prefix => item.startsWith(prefix))))

            setTableData(datas)
            setIsLoading(false)
        }
        fetchData()

    }, [userContext])

    
    if (isLoading) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    return (<>
        <PageTitle1> • AuditTrail</PageTitle1>

        <Card className='card'>
            <Table size='small' dataSource={tableData} rowClassName={'table-row-pointer'}
                columns={columns}
                pagination={paginationOptions} />
        </Card>

        <Modal title="Details" open={isModalOpen} footer={null} onCancel={handleCancel}>
            {modalContent}
        </Modal>
    </>)
}

export default AuditTrail