import { Layout, List, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import BlogTitle1 from '../components/BlogTitle1';
import MetaTags from '../MetaTags';
import styles from '../styles/faq.module.css';


export const FAQ_CRUMB = [
    {
        title: <Link to="/">Home</Link>,
    },
    {
        title: <Link to="/faq">FAQ</Link>,
    },
]

const FAQ = () => {

    const faqQuestions = [
        { title: 'What is Just Trade It?', link: '/faq/what-is-just-trade-it' },
        { title: 'How does Just Trade It benefit users?', link: '/faq/just-trade-it-benefits' },
        { title: 'What are the Buy and Sell Signals and How to Use Them?', link: '/faq/buy-sell-signals' },
        { title: 'How does the JTI AI Model work?', link: '/faq/jti-ai-model' },
        { title: 'What do the tags like "Resilient" and "Win Rate High" mean?', link: '/faq/tags-meaning' },
        // { title: 'What are L1 and L2 signals?', link: '/faq/l1-l2-signals' },
        // { title: 'What are the Take Profit levels?', link: '/faq/take-profit-levels' },
        // { title: 'Does the AI Model predict future asset prices?', link: '/faq/ai-model-predictions' },
        // { title: 'What does "Win Rate Efficiency" mean?', link: '/faq/win-rate-efficiency' },
        // { title: 'What is a dividend yield?', link: '/faq/dividend-yield' },
        // { title: 'What is the ex-dividend date?', link: '/faq/ex-dividend-date' },
        // { title: 'What does "short ratio" mean?', link: '/faq/short-ratio' },
        // { title: 'Do I need trading experience to use Just Trade It?', link: '/faq/trading-experience-needed' },
        // { title: 'Is there a free trial or demo?', link: '/faq/free-trial-demo' },
        // { title: 'How can I get support?', link: '/faq/get-support' },
        // { title: 'Is Just Trade It a financial advisor?', link: '/faq/financial-advisor' },
        // { title: 'Can I rely solely on the AI Model for trading decisions?', link: '/faq/ai-model-reliance' },
    ];

    return (
        <Layout className="site-layout-content-fixed">
            <MetaTags
                title="Just Trade It: Frequently Asked Questions (FAQ)"
                description="Frequently Asked Questions (FAQ)"
                keywords="Frequently Asked Questions, FAQ" />

            <BlogTitle1>• Frequently Asked Questions (FAQ)</BlogTitle1>

            <List size='large' bordered className={styles['list-questions']}
                itemLayout="horizontal"
                dataSource={faqQuestions}
                renderItem={(item) => (
                    <List.Item>
                        <Link className="none-color-link" to={item.link}>{item.title}</Link>
                    </List.Item>
                )}
            />
        </Layout>
    )
}

export default FAQ;