import { Menu, Layout } from 'antd';
import React, { useState } from "react";
import { isMobile } from '../utils/utils';

const { Sider } = Layout;

const LeftMenu = ({ defaultKey, menuItems, handleSelectedMenu }) => {
    
    const [current, setCurrent] = useState(defaultKey);

    const onClick = (e) => {
        setCurrent(e.key);
    }

    if (!isMobile())
        return (
            <Sider className="sider-left" style={{ paddingTop: '66px' }} width={190}>
                <Menu className='left-menu' mode="inline" style={{ paddingLeft: '5px', }} onClick={onClick} selectedKeys={[current]} onSelect={({ item, key, keyPath, selectedKeys, domEvent }) => handleSelectedMenu(key)}>
                    {menuItems.map(item => (
                        <Menu.Item className='left-menu-item' key={item.key} icon={item.icon}>
                            <span>{item.label}</span>
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
        )
    else
        // MOBILE
        return (
            <Menu mode="inline" style={{ marginBottom: '18px', }} onClick={onClick} selectedKeys={[current]} onSelect={({ item, key, keyPath, selectedKeys, domEvent }) => handleSelectedMenu(key)}>
                {menuItems.map(item => (
                    <Menu.Item key={item.key} icon={item.icon}>
                        <span>{item.label}</span>
                    </Menu.Item>
                ))}
            </Menu>
        )
}

export default LeftMenu;