import { blue, volcano, red } from '@ant-design/colors'
import Icon, { InfoCircleOutlined, LoadingOutlined, WarningOutlined, ProfileOutlined } from '@ant-design/icons';
import { Badge, Tooltip, Spin } from 'antd';
import { InfoBalanceSheet, BuySignalDesc, InfoDivYield, InfoExDivDate, InfoHoldingsChart, InfoIncomeStatements, InfoAVGROI, SellSignalDesc, InfoShortRatio, InfoWinLose, WarnShortRatio, InfoTradesSummaryChart, InfoAMedianSwing, InfoChartGainsByIndustry, InfoChartGLByAsset } from './TempLang';
import CandlestickChartTT from './tooltips/CandlestickChartTT';
import { ReactComponent as LearnSVG } from '../svg/learn.svg';
import { COLOR_SIGNAL_BUY_L1, COLOR_SIGNAL_BUY_L2, COLOR_SIGNAL_SELL_L1, COLOR_SIGNAL_SELL_L2, TT_COLOR } from '../JTIConst';


// GENERAL //

export const IconInHoldings = () => {
    return (
        <Tooltip color={TT_COLOR} title="This asset is part of your holdings">
            <sup><ProfileOutlined style={{ fontSize: '0.6em' }} /></sup>
        </Tooltip>)
}

// NOTUSED
export const IconLearn = () => {
    return <Icon component={LearnSVG} style={{ color: '#fff', fontSize: '1.45em' }} />
}


// INFOS //

const TITLE_ICON_INFO_STYLE = { float: 'right', fontSize: '0.6em', margin: '12px 5px 0 0', color: 'grey' }
const SUB_TITLE_ICON_INFO_STYLE = { fontSize: '1.3em', color: 'grey' }

export const IconInfoCandlestickChart = () => {
    return (
        <Tooltip placement='left' color={TT_COLOR} title={<CandlestickChartTT />}>
            <InfoCircleOutlined style={{ fontSize: '1.2em', color: 'grey' }} />
        </Tooltip>)
}

export const IconInfoIncomeStatement = () => {
    return (
        <Tooltip placement='left' color={TT_COLOR} title={<InfoIncomeStatements />}>
            <InfoCircleOutlined style={SUB_TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoBalanceSheet = () => {
    return (
        <Tooltip placement='left' color={TT_COLOR} title={<InfoBalanceSheet />}>
            <InfoCircleOutlined style={SUB_TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoHoldings = () => {
    return (
        <Tooltip placement='left' color={TT_COLOR} title={<InfoHoldingsChart />}>
            <InfoCircleOutlined style={SUB_TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoIndexesView = () => {
    return (
        <Tooltip color={TT_COLOR} title="The indexes view allow you to see at a glance the market cycle phase/trend.">
            <InfoCircleOutlined style={TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoCalendar = () => {
    return (
        <Tooltip color={TT_COLOR} title="The calendar allows you to see important upcoming events, such as company earnings.">
            <InfoCircleOutlined style={TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoFinancialGraph = () => {
    return (
        <Tooltip color={TT_COLOR} title={
            <>
                The financial dependency graph allows you to see the relationship between financial companies and anticipate potential side effects.
                As the graph is interactive, you can filter by legend or highlight any connections of interest.
            </>
        }>
            <InfoCircleOutlined style={TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoChartGLByAsset = () => {
    return (
        <Tooltip placement='left' color={TT_COLOR} title={<InfoChartGLByAsset />}>
            <InfoCircleOutlined style={SUB_TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}

export const IconInfoChartGainsByIndustry = () => {
    return (
        <Tooltip placement='left' color={TT_COLOR} title={<InfoChartGainsByIndustry />}>
            <InfoCircleOutlined style={SUB_TITLE_ICON_INFO_STYLE} />
        </Tooltip>)
}


const iconInfoCircleBorder = <sup><InfoCircleOutlined style={{ fontSize: '1em', position: 'relative', right: '-2px' }} /></sup>
const iconInfoCircleBorderDesc = <sup><InfoCircleOutlined style={{ fontSize: '0.95em', position: 'relative', right: '-2px', top: '-5px' }} /></sup>

// TODO : Use DB variable for the 5 days period
export const IconInfoActiveBuySignal = () => {
    return (
        <Tooltip color={TT_COLOR} title={<>
            {BuySignalDesc}<br />
            Colors mean that the signal has been triggered; active signals last five days.
        </>}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconInfoActiveSellSignal = () => {
    return (
        <Tooltip color={TT_COLOR} title={<>
            {SellSignalDesc}<br />
            Colors mean that the signal has been triggered; active signals last five days.
        </>}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconLoading = ({ fontSize = 60 }) => {
    const antIcon = <LoadingOutlined style={{ fontSize: fontSize }} spin />
    return (<Spin style={{ margin: 'auto' }} indicator={antIcon} />)
}

export const IconInfoExDivDate = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoExDivDate />}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconInfoDivYield = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoDivYield />}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconInfoShortRatio = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoShortRatio />}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconInfoAVGROI = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoAVGROI />}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconInfoWinLose = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoWinLose />}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}

export const IconInfoMedianSwing = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoAMedianSwing />}>
            {iconInfoCircleBorderDesc}
        </Tooltip>)
}


// WARN //

const warnStyle = { fontSize: '1em', margin: '0 0 0 5px', color: 'red' }

export const IconWarn = () => <WarningOutlined style={warnStyle} />

export const IconWarnShortRatio = () => {
    return (
        <Tooltip placement='top' color={TT_COLOR} title={<WarnShortRatio />}>
            <sup><WarningOutlined style={warnStyle} /></sup>
        </Tooltip>)
}


// CANDLESTICK CHART //

export const IconSignalOff = () => {
    return <Badge status='default' className='signal' />;
}

const shiningPointStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    // animation: 'shine 1s infinite alternate',
    // animationTimingFunction: 'ease-in-out',
    // boxShadow: '0 0 4px rgba(0, 0, 255, 0.7)', // Add an outer glow effect
}
const lineStyle = {
    width: '13px',
    height: '3px',
    borderRadius: '10%',
    display: 'inline-block',
    marginBottom: '0.2em'
}

export const IconSignalBuyL1 = () => {
    return <Badge color={COLOR_SIGNAL_BUY_L1} className='signal' status="processing" />;
}
export const IconSignalBuyL1TT = () => {
    return <span style={{ ...shiningPointStyle, backgroundColor: COLOR_SIGNAL_BUY_L1 }} />;
}

export const IconSignalBuyL2 = () => {
    return <Badge color={COLOR_SIGNAL_BUY_L2} className='signal' status="processing" />;
}
export const IconSignalBuyL2TT = () => {
    return <span style={{ ...shiningPointStyle, backgroundColor: COLOR_SIGNAL_BUY_L2 }} />;
}

export const IconSignalSellL1 = () => {
    return <Badge color={COLOR_SIGNAL_SELL_L1} className='signal' status="processing" />;
}
export const IconSignalSellL1TT = () => {
    return <span style={{ ...shiningPointStyle, backgroundColor: COLOR_SIGNAL_SELL_L1 }} />;
}

export const IconSignalSellL2 = () => {
    return <Badge color={COLOR_SIGNAL_SELL_L2} className='signal' status="processing" />;
}
export const IconSignalSellL2TT = () => {
    return <span style={{ ...shiningPointStyle, backgroundColor: COLOR_SIGNAL_SELL_L2 }} />;
}

export const IconEMA = () => {
    return <span style={{ ...lineStyle, backgroundColor: red[5] }} />;
}

export const IconMA = () => {
    return <span style={{ ...lineStyle, backgroundColor: blue[4] }} />;
}

export const IconVolume = () => {
    return <img src='/legend_volume.png' style={{ width: '22px', verticalAlign: 'bottom', marginRight: '6px' }} />;
}

export const IconEarning = () => {
    return <img src='/legend_earning.png' style={{ width: '22px', verticalAlign: 'bottom', marginRight: '5px' }} />;
}

export const IconDividend = () => {
    return <img src='/legend_dividend.png' style={{ width: '24px', verticalAlign: 'middle', marginRight: '5px' }} />;
}

export const IconBullFlag = () => {
    return <img src='/legend_bull_flag.png' style={{ width: '22px', verticalAlign: 'middle', marginRight: '6px' }} />;
}

export const IconBearFlag = () => {
    return <img src='/legend_bear_flag.png' style={{ width: '22px', verticalAlign: 'middle', marginRight: '6px' }} />;
}


// ORDERS //

// https://www.svgrepo.com/svg/491467/plus
export const svgBuyOrder = 'M13.5 3C13.5 2.44772 13.0523 2 12.5 2H11.5C10.9477 2 10.5 2.44772 10.5 3V10.5H3C2.44772 10.5 2 10.9477 2 11.5V12.5C2 13.0523 2.44772 13.5 3 13.5H10.5V21C10.5 21.5523 10.9477 22 11.5 22H12.5C13.0523 22 13.5 21.5523 13.5 21V13.5H21C21.5523 13.5 22 13.0523 22 12.5V11.5C22 10.9477 21.5523 10.5 21 10.5H13.5V3Z'
// https://www.svgrepo.com/svg/491452/minus
export const svgSellOrder = 'M2 11.5C2 10.9477 2.44772 10.5 3 10.5L21 10.5C21.5523 10.5 22 10.9477 22 11.5V12.5C22 13.0523 21.5523 13.5 21 13.5H3C2.44772 13.5 2 13.0523 2 12.5V11.5Z'
export const IconBuyOrder = () => {
    return (
        <svg width="13px" height="13px" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={blue[6]} strokeWidth="1.2">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke={blue[6]} strokeWidth="0.288"></g>
            <g id="SVGRepo_iconCarrier">
                <path d={svgBuyOrder} fill={blue[6]}>
                </path>
            </g>
        </svg>
    )
}

export const IconSellOrder = () => {
    return (
        <svg width="13px" height="13px" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={volcano[6]} strokeWidth="1.2">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke={volcano[6]} strokeWidth="0.288"></g>
            <g id="SVGRepo_iconCarrier">
                <path fillRule="evenodd" clipRule="evenodd" d={svgSellOrder} fill={volcano[6]}>
                </path>
            </g>
        </svg>
    )
}
