import { CHART_COLOR_STYLES } from '../../JTIConst';
import { getInstanceByDom, init } from 'echarts';
import React, { useEffect, useRef, useState } from "react";
import '../../styles/IndexesView.css';
import { formatDateToBetterReading, formatNumberIn } from '../../utils/utils';
import { Card } from 'antd';


// SHARED VARIABLES //


// PARAMETERS //

// Chart configuration
const CHART_ZOOM_THROTTLE = 50


const LineChart = ({ style, ...props}) => {

    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({});

    useEffect(() => {

        setLoading(true);

        let legend = []
        let series = []
        let i = 0
        props.series.map(serie => {
            legend.push({
                name: serie.name,
                itemStyle: {
                    opacity: 0.5
                }
            })
            series.push({
                name: serie.name,
                type: 'line',
                stack: serie.name,
                showSymbol: false,
                smooth: true,
                data: serie.data,
                lineStyle: {
                    width: 1,
                    color: CHART_COLOR_STYLES[props.color]
                },
                areaStyle: {
                    opacity: 0.3,
                    color: CHART_COLOR_STYLES[props.color]
                },
                emphasis: {
                    focus: 'series'
                },
            })
            i++
        })

        setOptions({
            legend: {
                data: legend
            },
            tooltip: {
                trigger: 'axis',
                valueFormatter: (value) => props.currency ? '$ ' + formatNumberIn(value) : formatNumberIn(value)
            },
            dataZoom: [
                {
                    type: 'inside',
                    throttle: CHART_ZOOM_THROTTLE,
                    // start: '50',
                    // endValue: '100',
                }
            ],
            grid: {
                left: '1%',
                right: '1%',
                bottom: '2%',
                containLabel: true
            },
            yAxis: {
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#333',
                        width: 1
                    },
                },
                axisTick: {
                    show: false,
                },
                // splitNumber: 8,
                axisLabel: {
                    formatter: (value, index) => { return formatNumberIn(value) }
                },
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: props.xAxis,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    formatter: (value, index) => {
                        return formatDateToBetterReading(value);
                    }
                }
            },
            series: series
        })

        setLoading(false);
    }, [props.series])


    useEffect(() => {
        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
            loading === true ? chart.showLoading() : chart.hideLoading();
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [loading]);                              // Run first to init echarts instance and dispose

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(options);
        }
    }, [options]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    return (
        <Card title={props.title} className='card' style={style}>
            <div ref={chartRef} style={{ height: "400px" }} />
        </Card>
    )

}

export default LineChart;
