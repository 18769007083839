import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/Blog.css';
import CommonStocksVSPreferredStocks from './blog/CommonStocksVSPreferredStocks';
import DiversifiedStockPortfolio from './blog/DiversifiedStockPortfolio';
import IndexFunds from './blog/IndexFunds';
import PriceToEarningsRatio from './blog/PriceToEarningsRatio';
import RatiosTerms from './blog/RatiosTerms';
import StockMarketInvesting from './blog/StockMarketInvesting';
import SwingResilientStrategy from './blog/SwingResilientStrategy';
import TheBasicsofDividendInvesting from './blog/TheBasicsofDividendInvesting';
import Top10GrowthStocks2025 from './blog/Top10GrowthStocks2025';
import TradeChecklist from './blog/TradingChecklist';
import Error from './Error';


const components = {
    'trading-checklist': <TradeChecklist />,
    'swing-resilient-strategy': <SwingResilientStrategy />,
    'common-stocks-vs-preferred-stocks': <CommonStocksVSPreferredStocks />,
    'the-basics-of-dividend-investing': <TheBasicsofDividendInvesting />,
    'top-10-growth-stocks-2025': <Top10GrowthStocks2025 />,
    'stock-market-investing': <StockMarketInvesting />,
    'understanding-ratios-terms-and-metrics-in-fundamental-analysis-for-stock-market-investing': <RatiosTerms />,
    'price-to-earnings-ratio': <PriceToEarningsRatio />,
    'index-funds': <IndexFunds />,
    'diversified-stock-portfolio': <DiversifiedStockPortfolio />
}


const BlogPost = () => {

    const { urlPath } = useParams();

    return components[urlPath] ? components[urlPath] : <Error />;
};

export default BlogPost;