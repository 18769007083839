import { Card, Col, Layout, Row, Statistic } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../components/UserContext';
import TradingBook from '../components/TradingBook';
import { TradingBookDescription } from '../components/TempLang';
import Paragraph from 'antd/es/typography/Paragraph';
import PortfolioGainLossChart from '../components/PortfolioGainLossChart';
import { displayData, formatNumber, getGainLossColor, getGainLossIcon } from '../utils/utils';
import { IconBuyOrder, IconSellOrder } from '../components/Icons';
import PageTitle1 from '../components/PageTitle1';
import PortfolioIndustryChart from '../components/PortfolioIndustryChart';
import MetaTags from '../MetaTags';
import { GRID_GUTTER } from '../JTIConst';


const Portfolio = () => {

    const { userContext } = useContext(UserContext)
    const [buyCount, setBuyCount] = useState('')
    const [sellCount, setSellCount] = useState('')
    const [avgGainLoss, setAvgGainLoss] = useState('')
    const [totalGainLoss, setTotalGainLoss] = useState('')


    // EFFECTS //

    useEffect(() => {
        if (userContext != null && userContext.stockTradingBook) {
            const tdgBk = userContext.stockTradingBook

            // Count buy / sell
            setBuyCount(tdgBk.filter(item => item.actionType === 'BUY').length)
            setSellCount(tdgBk.filter(item => item.actionType === 'SELL').length)
            const sellOrders = tdgBk.filter(item => item.actionType === 'SELL')

            // AVG gain/loss  per trade
            const sumGL = sellOrders.reduce((acc, item) => { return acc + item.gainLoss }, 0)
            setAvgGainLoss(sumGL / sellOrders.length)

            // Total gain/loss
            const totalGL = sellOrders.reduce((acc, item) => { return acc + item.gainLossAmount }, 0)
            setTotalGainLoss(totalGL)
        }
    }, [userContext])

    return (
        <Layout className='site-layout-content'>
            <MetaTags title="Just Trade It: Portfolio" />

            <PageTitle1> • Portfolio</PageTitle1>

            <Row align='middle' gutter={GRID_GUTTER} className="grid-bottom">
                <Col xs={24} md={10}>
                    <Paragraph style={{ borderBottom: '1px solid #000', paddingBottom: '1em' }}>
                        <TradingBookDescription />
                    </Paragraph>
                </Col>
                <Col xs={12} md={3}>
                    <Card className='card'>
                        <Statistic title={<><IconBuyOrder /> &nbsp;BUY</>}
                            value={buyCount}
                        />
                    </Card>
                </Col>
                <Col xs={12} md={3}>
                    <Card className='card'>
                        <Statistic title={<><IconSellOrder /> &nbsp;SELL</>}
                            value={sellCount}
                        />
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card className='card'>
                        <Statistic title="Average Gain/Loss"
                            value={displayData(avgGainLoss)}
                            precision={2}
                            valueStyle={{ color: getGainLossColor(avgGainLoss) }}
                            prefix={getGainLossIcon(avgGainLoss)}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card className='card'>
                        <Statistic title="Total Gain/Loss"
                            value={`$ ${formatNumber(totalGainLoss)}`}
                            precision={2}
                            valueStyle={{ color: getGainLossColor(avgGainLoss) }}
                            prefix={getGainLossIcon(avgGainLoss)}
                        />
                    </Card>
                </Col>
            </Row>

            <TradingBook className="grid-bottom" viewMode={false} />

            <Row align='middle' gutter={GRID_GUTTER}>
                <Col xs={24} md={12}>
                    <PortfolioGainLossChart />
                </Col>
                <Col xs={24} md={12}>
                    <PortfolioIndustryChart />
                </Col>
            </Row>
        </Layout >
    )
}

export default Portfolio;