import { LeftOutlined, UpOutlined } from '@ant-design/icons';
import { scrollToTop } from '../utils/utils';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';


const BlogPostNavigationBottom = () => {

    return (
        <div style={{ fontSize: '1.1em' }}>
            <Divider />
            <Link to='#' style={{ float: 'right' }} onClick={scrollToTop}><UpOutlined style={{ width: '1.3em' }} /> Top</Link>
            <Link to='/blog'><LeftOutlined style={{ width: '1.3em' }} /> Back</Link>
        </div>)
}

export default BlogPostNavigationBottom;