import { SearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, DatePicker, Select, Space, Button } from 'antd';
import TickerSearchBox from '../components/TickerSearchBox';
import { DATE_DAY_FIRST } from '../JTIConst';


// EDITABLE CELL //

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

export const EditableCell = ({
    title,
    editable,
    type,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);


    // EFFECTS

    useEffect(() => {
        if (editing && type !== 'symbol') {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        })
    }

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    }

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0, }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}>
                {
                    (type === 'number') ? <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} /> :
                        (type === 'numberNoNegative') ? <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} /> :
                            (type === 'date') ? <DatePicker ref={inputRef} onPressEnter={save} onChange={save} open={true} format={DATE_DAY_FIRST} /> :
                                (type === 'action') ? <Select
                                    ref={inputRef} onSelect={save} onBlur={save} autoFocus={true} defaultOpen={true}
                                    options={[
                                        { value: 'BUY', label: 'BUY' },
                                        { value: 'SELL', label: 'SELL' },
                                    ]}
                                /> :
                                    (type === 'symbol') ? <TickerSearchBox size='small' onSelect={save} onBlur={save} autoFocus={true} defaultOpen={true} /> :
                                        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                }
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

// EDITABLE ROW //

export const EditableCellRow = ({
    editing,
    dataIndex,
    title,
    type,
    record,
    index,
    children,
    ...restProps
}) => {

    let inputNode = <Input />
    let inputRule = [
        {
            required: true,
            message: `Please input ${dataIndex}`,
        }
    ]

    if (type === 'numberAboveZero') {
        inputNode = <InputNumber min={1} />
        inputRule = [
            {
                required: true,
                message: `Please input ${dataIndex}`,
            },
            {
                validator: (_, value) =>
                    value > 0 ? Promise.resolve() : Promise.reject(new Error('The number must be greater than 0')),
            },
        ]
    } else if (type === 'numberNoNegative') {
        inputNode = <InputNumber min={0} />;
    } else if (type === 'date') {
        inputNode = <DatePicker format={DATE_DAY_FIRST} />
    } else if (type === 'action') {
        inputNode = (
            <Select options={[
                { value: 'BUY', label: 'BUY' },
                { value: 'SELL', label: 'SELL' },
            ]} />
        );
    } else if (type === 'symbol') {
        inputNode = <TickerSearchBox size="medium" onSelect={() => { }} />
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0, }}
                    rules={inputRule}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}


// COLUMN CUSTOM SEARCH FILTER MENU //

// const [searchText, setSearchText] = useState('');
// const [searchedColumn, setSearchedColumn] = useState('');
const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
}
const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    // setSearchText('');
    handleSearch(selectedKeys, confirm, dataIndex)
}
export const getColumnSearchProps = (ref, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input ref={ref} style={{ marginBottom: 8, display: 'block' }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)} />
            <Space>
                <Button type="primary" size="small" icon={<SearchOutlined />}
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
                    Search
                </Button>
                <Button size="small"
                    onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}>
                    Reset
                </Button>
                <Button type="link" size="small"
                    onClick={() => {
                        confirm({
                            closeDropdown: false,
                        });
                        // setSearchText(selectedKeys[0]);
                        // setSearchedColumn(dataIndex);
                    }}>
                    Filter
                </Button>
                <Button type="link" size="small" onClick={() => { close(); }}>
                    close
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, }} />
    ),
    onFilter: (value, record) =>
        record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownProps: {
        onOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => ref.current?.select(), 100);
            }
        }
    },
    render: (text) => text
})