import { Layout, Typography, theme, notification, Skeleton, Col, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { IconLoading, IconInfoIndexesView } from './Icons';
import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getTickerInfos } from "../services/ticker.service";
import { isAllowedToViewIndexView } from "../services/authorization.service";
import UserContext from '../components/UserContext';
import IndexCard from './IndexCard';
import { TagTrendBear, TagTrendBull } from './Tags';
import PageTitle1 from './PageTitle1';


const { Link, } = Typography;

const IndexesView = () => {

    const {
        token: { colorPrimary },
    } = theme.useToken();
    const { userContext } = useContext(UserContext);

    // Notification
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement) => {
        api.info({
            message: 'Limited Account',
            // TODO: Add link to upgrade
            description:
                <>
                    The indexes view is not allowed for your account.
                    Consider to <Link href="/pricing">upgrade</Link> for more features.
                </>,
            placement,
            duration: 300,
            icon: (<WarningOutlined style={{ color: colorPrimary, }} />),
        });
    };

    const { getAccessTokenSilently } = useAuth0();

    const [spxInfos, setSpxInfos] = useState([]);
    const [spxTag, setSpxTag] = useState([]);

    const [nsdqInfos, setNsdqInfos] = useState([]);
    const [nsdqTag, setNsdqTag] = useState([]);

    const [djiInfos, setDjiInfos] = useState([]);
    const [djiTag, setDjiTag] = useState([]);

    const [tsxInfos, setTsxInfos] = useState([]);
    const [tsxTag, setTsxTag] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            if (userContext != null) {

                // MARK SEC - Indexes view
                if (isAllowedToViewIndexView(userContext)) {
                    const accessToken = await getAccessTokenSilently();
                    const spxResult = await getTickerInfos(accessToken, '^GSPC');
                    const nsdqResult = await getTickerInfos(accessToken, '^IXIC');
                    const djiResult = await getTickerInfos(accessToken, '^DJI');
                    const tsxResult = await getTickerInfos(accessToken, '^GSPTSE');

                    if (spxResult.data.trend == 'Bull') {
                        setSpxTag(<TagTrendBull />)
                    } else {
                        setSpxTag(<TagTrendBear />)
                    }

                    if (nsdqResult.data.trend == 'Bull') {
                        setNsdqTag(<TagTrendBull />)
                    } else {
                        setNsdqTag(<TagTrendBear />)
                    }

                    if (djiResult.data.trend == 'Bull') {
                        setDjiTag(<TagTrendBull />)
                    } else {
                        setDjiTag(<TagTrendBear />)
                    }

                    if (tsxResult.data.trend == 'Bull') {
                        setTsxTag(<TagTrendBull />)
                    } else {
                        setTsxTag(<TagTrendBear />)
                    }

                    setSpxInfos(spxResult.data);
                    setNsdqInfos(nsdqResult.data);
                    setDjiInfos(djiResult.data);
                    setTsxInfos(tsxResult.data);
                } else {
                    openNotification('topRight')
                    console.log("account type:", userContext.accountType)
                }
            }
        };

        fetchData();
    }, [userContext]);

    if (userContext === null) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>
        )
    }

    const rowGutt = [34, 34]
    return (
        <>
            {contextHolder}
            <PageTitle1> • Indexes View<IconInfoIndexesView /></PageTitle1>

            {isAllowedToViewIndexView(userContext) ? (

                <Layout>
                    <Row gutter={rowGutt} justify="space-around" align="middle">
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <IndexCard title='SPX500' ticker='^GSPC' tag={spxTag} data={spxInfos} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <IndexCard title='NASDAQ' ticker='^IXIC' tag={nsdqTag} data={nsdqInfos} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <IndexCard title='DOW JONES' ticker='^DJI' tag={djiTag} data={djiInfos} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <IndexCard title='TSX' ticker='^GSPTSE' tag={tsxTag} data={tsxInfos} />
                        </Col>
                    </Row>
                </Layout>

            ) : (

                <Skeleton paragraph={{ rows: 4 }} />

            )}
        </>
    )
}
export default IndexesView;