import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import './App.css';
import { AuthenticationGuard } from "./components/authentication-guard";
import AboutJTI from "./pages/AboutJTI";
import Account from './pages/Account';
import Admin from './pages/admin/Admin';
import AppLayout from "./pages/AppLayout";
import Blog from "./pages/Blog";
import BlogPost from './pages/BlogPost';
import Demo from './pages/Demo';
import Error from "./pages/Error";
import FAQ from './pages/FAQ';
import FAQPost from './pages/FAQPost';
import Home from './pages/Home';
import JTIAIModel from './pages/JTIAIModel';
import Landing from './pages/landings/Landing';
import Login from './pages/Login';
import MacroView from "./pages/MacroView";
import NewUserStartup from "./pages/NewUserStartup";
import Portfolio from './pages/Portfolio';
import Pricing from "./pages/Pricing";
import Privacy from './pages/Privacy';
import Screener from './pages/Screener';
import Terms from "./pages/Terms";
import TickerAnalysis from "./pages/TickerAnalysis";
import Watchlist from "./pages/Watchlist";
import { scrollToTop } from './utils/utils';


function App() {

    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        // Save utm id in user session if present
        const utmId = searchParams.get('utm_id')
        if (utmId !== null) {
            sessionStorage.setItem('utm_id', utmId);
        }

        // Scroll to the top when the location (route) change
        scrollToTop()
    }, [location])


    // MARK: Protect pages
    return (
        <Routes>
            <Route path="/" element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path="landing" element={<Landing />} />

                <Route path="login" element={<Login />} />
                <Route path="demo" element={<Demo />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="newuserstartup" element={<AuthenticationGuard component={NewUserStartup} />} />

                <Route path="screener" element={<AuthenticationGuard component={Screener} />} />
                <Route path="watchlist" element={<AuthenticationGuard component={Watchlist} />} />
                <Route path="portfolio" element={<AuthenticationGuard component={Portfolio} />} />
                <Route path="tickeranalysis" element={<AuthenticationGuard component={TickerAnalysis} />} />
                <Route path="tickeranalysis/:paramTicker" element={<AuthenticationGuard component={TickerAnalysis} />} />
                <Route path="macroview" element={<AuthenticationGuard component={MacroView} />} />

                <Route path="blog" element={<Blog />} />
                <Route path="/blog/:urlPath" element={<BlogPost />} />

                <Route path="faq" element={<FAQ />} />
                <Route path="/faq/:urlPath" element={<FAQPost />} />

                <Route path="about-jti" element={<AboutJTI />} />
                <Route path="jti-ai-model" element={<JTIAIModel />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />

                <Route path="account" element={<AuthenticationGuard component={Account} />} />
                {/* TODO : Implement admin guard */}
                <Route path="admin" element={<AuthenticationGuard component={Admin} />} />
                <Route path="admin/:paramMenuKey" element={<AuthenticationGuard component={Admin} />} />

                <Route path="*" element={<Error />} />
            </Route>
        </Routes>
    )
}

export default App;