import { volcano } from '@ant-design/colors';
import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { COLOR_BLUE, COLOR_CYAN, COLOR_GREEN, COLOR_ORANGE, COLOR_PRIMARY, TT_COLOR } from '../JTIConst';
import { InfoAIEfficiency, InfoTagAIHoldingStatusHold, InfoTagResilient, InfoTagYoung } from './TempLang';

const COLOR_RED = volcano[6]

const fontStyle = { fontWeight: '600', borderRadius: '3px' }


export const TagAITagging = (props) => {
    return <Tag color={COLOR_GREEN} style={{ ...fontStyle, ...props.style }}>AI Tagging System</Tag>;
}

// Asset Classification

export const TagClassResilient = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagResilient />}>
            <Tag color={COLOR_GREEN} style={fontStyle}><CheckOutlined /> Resilient</Tag>
        </Tooltip>)
}

export const TagClassYoung = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagYoung />}>
            <Tag color={COLOR_RED} style={fontStyle}><WarningOutlined /> Young</Tag>
        </Tooltip>)
}

// AI model efficiency

export const TagAIHigh = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoAIEfficiency />}>
            <Tag color={COLOR_BLUE} style={fontStyle}><CheckOutlined /> Win Rate High</Tag>
        </Tooltip>)
}

export const TagAIGood = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoAIEfficiency />}>
            <Tag color={COLOR_CYAN} style={fontStyle}><CheckOutlined /> Win Rate Good</Tag>
        </Tooltip>)
}

export const TagAIAverage = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoAIEfficiency />}>
            <Tag color={COLOR_ORANGE} style={fontStyle}><WarningOutlined /> Win Rate Avg</Tag>
        </Tooltip>)
}

export const TagAILow = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoAIEfficiency />}>
            <Tag color={COLOR_RED} style={fontStyle}><WarningOutlined /> Win Rate Low</Tag>
        </Tooltip>)
}

// AI holding status

export const TagAIHoldingStatusHold = () => {
    return (
        <Tooltip color={TT_COLOR} title={<InfoTagAIHoldingStatusHold />}>
            {/* <Tag color={COLOR_GREEN} style={fontStyle}><Icon component={hodor} style={{ color: '#fff' }} /> Hodor</Tag> */}
            <Tag color={COLOR_GREEN} style={fontStyle}>Hold</Tag>
        </Tooltip>)
}

// Market Trend

export const TagTrendBear = () => {
    return <Tag icon={<ArrowDownOutlined />} color={COLOR_RED} style={fontStyle}>Bear Market</Tag>
}

export const TagTrendBull = () => {
    return <Tag icon={<ArrowUpOutlined />} color={COLOR_GREEN} style={fontStyle}>Bull Market</Tag>
}

// Risk NOTUSED

export const TagRiskL1 = () => {
    return <Tag color={COLOR_GREEN} style={fontStyle}>Risk -</Tag>;
}

export const TagRiskL2 = () => {
    return <Tag color={COLOR_GREEN} style={fontStyle}>Risk +</Tag>;
}

export const TagRiskL3 = () => {
    return <Tag color={COLOR_GREEN} style={fontStyle}>Risk ++</Tag>;
}


// Terms

export const TagShortTerm = () => {
    return <Tag color={COLOR_GREEN} style={fontStyle}>Short Term</Tag>;
}

export const TagMediumTerm = () => {
    return <Tag color={COLOR_ORANGE} style={fontStyle}>Medium Term</Tag>;
}

export const TagLongTerm = () => {
    return <Tag color={COLOR_RED} style={fontStyle}>Long Term</Tag>;
}


// Charts

export const TagSupport = () => {
    return <Tag color="grey" style={fontStyle}><CheckOutlined /> Support</Tag>;
}

export const TagResistance = () => {
    return <Tag color="grey" style={fontStyle}><CheckOutlined /> Resistance</Tag>;
}

// Refresh chart to see changes
const chartTTTagStyle = { fontWeight: '520', letterSpacing: '1px', lineHeight: '1.6em', padding: '0.1rem 0.4rem', display: 'inline-block', borderRadius: '4px', color: '#fff' }
export const TagSupportTT = () => {
    return <Tag color={COLOR_PRIMARY} style={chartTTTagStyle} bordered={false}><CheckOutlined /> Support</Tag>;
}

export const TagResistanceTT = () => {
    return <Tag color={COLOR_PRIMARY} style={chartTTTagStyle} bordered={false}><CheckOutlined /> Resistance</Tag>;
}