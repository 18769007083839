import { ReactComponent as hodor } from '../svg/hodor.svg';
import { Link } from "react-router-dom";
import Icon from '@ant-design/icons';
import { IconBuyOrder, IconEMA, IconMA, IconSellOrder, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2 } from "./Icons";


// TODO : Lang


// TRADING PROFILES //

export const InfoTradingProfileDCADesc = <>The <b>Dollar Cost Averaging</b> profile enables receiving <b>buy signal: &nbsp;<IconSignalBuyL1 />L1 &nbsp;&nbsp;<IconSignalBuyL2 />L2</b> for the <b>selected items</b> in your watchlist.</>
export const InfoTradingProfileSwingDesc = <>The <b>Swing Trader</b> profile enables receiving <b>buy signal: &nbsp;<IconSignalBuyL1 />L1 &nbsp;&nbsp;<IconSignalBuyL2 />L2</b> and <b>sell signal: &nbsp;<IconSignalSellL1 />L1 &nbsp;&nbsp;<IconSignalSellL2 />L2 alerts</b> for the <b>selected items</b> in your watchlist.</>
export const InfoTradingProfileActiveDesc = <>The <b>Active Trader</b> profile enables receiving <b>buy signal: &nbsp;<IconSignalBuyL1 />L1 &nbsp;&nbsp;<IconSignalBuyL2 />L2</b>, <b>sell signal: &nbsp;<IconSignalSellL1 />L1 &nbsp;&nbsp;<IconSignalSellL2 />L2</b> and <b>take profit: <IconEMA /> L1 &nbsp;<IconMA /> L2 alerts</b> for the <b>selected items</b> in your watchlist.</>

// SIGNALS //

export const BuySignalDesc = <>
    AI-generated Buy Signal by analyzing asset <b>behavior, patterns, support, resistance, and volatility</b>.<br />
    A <b>higher level</b> indicates a greater probability that the asset has reached the <b>bottom</b> of its current trend.
</>
export const SellSignalDesc = <>
    AI-generated sell signal by analyzing asset <b>behavior, patterns, support, resistance, and volatility</b>.<br />
    A <b>higher level</b> indicates a greater probability that the asset has reached the <b>top</b> of its current trend.
</>
export const TakeProfitDesc = <>
    Take profit represents <b>layers</b> to exit your trade when the <b>context is uncertain</b>.<br />
    You can sell part of your shares layer by layer or all at once.
</>

export const InfoBuySignal = () => {
    return (<>Buy signal Level 1 <b>(L1)</b> or Level 2 <b>(L2)</b>: {BuySignalDesc}</>)
}

export const InfoSellSignal = () => {
    return (<>Sell signal Level 1 <b>(L1)</b> or Level 2 <b>(L2)</b>: {SellSignalDesc}</>)
}

// CHART BUTTONS //

export const FullScreen = 'Full screen'
export const Reset = 'Reset'
export const Measure = 'Measure gain/loss'
export const ShowSuppResis = 'Show resistances and supports'
export const PriceTargets = 'Show analyst price targets'

// TAG //

export const InfoAIEfficiency = () => {
    return (<>
        All assets available on the Just Trade It Platform are analyzed by the AI Model.<br />
        However, it's important to note that the AI Model doesn't offer the same <b>efficiency</b> for <b>all assets</b>.<br />
        These tags allows to <b>rate the AI Model Efficiency</b> for a specific asset. The rates are in the following order: <b>High, Good, Avg, Low</b>.
    </>)
}

export const InfoTagResilient = () => {
    return (<>
        Stocks evaluated as "resilient" by the AI Model.<br />
        This includes assets that meet criteria such as significant capitalization, low volatility, and a proven track record of resilience during intense bear markets.
    </>)
}

export const InfoTagYoung = () => {
    return (<>
        Symbols evaluated as "young", in contrast to the resilient tag, indicate assets with limited data and a short period of market exposure.
    </>)
}

export const InfoTagAIHoldingStatusHold = () => {
    return (<>
        <Icon component={hodor} style={{ color: 'black', float: 'left', fontSize: '6em' }} />
        "Hold the door!!" - The asset is in the middle of his current cycle, <b>hold the asset</b> until a <b>sell signal</b> is trigger
        or until the asset reach a <b>take profit</b> level.
    </>)
}

// TERMS //

export const InfoExDivDate = () => <>To receive a stock’s upcoming dividend, an investor must purchase shares of the stock and hold it at the ex-dividend date.</>
export const InfoDivYield = () => <>A dividend yield is a ratio that shows you how much income you earn in dividend payouts per year for every dollar invested in a stock.</>
export const InfoShortRatio = () => <>The number of shares of a security that investors have sold short divided by the average daily volume of the security.</>
export const InfoAVGROI = () => <>If you replay the <b>chart history</b> and execute <b>buy</b> and <b>sell</b> orders at each <b>first signal occurrence</b>,
    this percentage would represent the <b>average return on investment</b> for <b>each individual trade</b>.</>
export const InfoWinLose = () => <>If you replay the <b>chart history</b> and execute <b>buy</b> and <b>sell</b> orders at each <b>first signal occurrence</b>,
    this ratio would represent the number of <b>winning trades</b> versus <b>losing trades</b>.</>
export const InfoAMedianSwing = () => <>Median time between the first buy signal and the first following sell signal.</>

export const InfoIncomeStatements = ({ short = false }) => {
    return (<>
        An income statement shows a company's revenues, expenses and profitability over a period of time.
        {!short && (
            <ul style={{ paddingLeft: '0' }}>
                <li><u><b>Total Revenue:</b></u> money generated from normal business operations.</li>
                <li><u><b>Gross Profit:</b></u> profit a company makes after deducting the costs associated with producing and selling its products or the costs associated with its services.</li>
                <li><u><b>EBIT:</b></u> Earnings before interest and taxes indicate a company's profitability. EBIT is calculated as revenue minus expenses excluding tax and interest.</li>
                <li><u><b>Net Income:</b></u> revenues minus expenses, interest, and taxes.</li>
            </ul>
        )}
    </>)
}

export const InfoBalanceSheet = ({ short = false }) => {
    return (<>
        A balance sheet summarizes a company's assets, liabilities and shareholders’ equity at a specific point in time.
        {!short && (
            <ul style={{ paddingLeft: '0' }}>
                <li><u><b>Total Assets:</b></u> represent all the economic resources owned or controlled by the company.</li>
                <li><u><b>Total Liabilities:</b></u> Liabilities are the legal debts a company owes to third-party creditors.</li>
                <li><u><b>Cash & Short Term Investments:</b></u> Cash and short term investments are considered very liquid assets. For instance, if a company needs immediate liquidity, cash and short term investments can be be quickly used.</li>
                <li><u><b>Long Term Debt:</b></u> debts a company owes third-party creditors that are payable beyond 12 months.</li>
            </ul>
        )}
    </>)
}

export const InfoHoldingsChart = () => {
    return (<>The Holdings Chart allows you to see, in one view, the <b>percentage ownership</b> of the selected company in relation to the total shares (left) and the companies that the selected <b>company owns</b> (right).</>)
}

export const InfoChartGLByAsset = () => {
    return (<>The "Gain/Loss By Asset" chart provides a comprehensive overview of consolidated gains and losses for each symbol registered in your trading book.</>)
}

export const InfoChartGainsByIndustry = () => {
    return (<>The “Gains By Industry” chart provides a comprehensive overview of consolidated gains across industries, making it easy to identify the most profitable sectors.</>)
}

export const WarnShortRatio = () => {
    return (<>The high short ratio indicates that many people are betting on the price going down.</>)
}

export const ReadTradingChecklist = () => {
    return (<>Please, review the <Link to="/blog/tradingchecklist">Trade Checklist</Link> before purchasing any shares.</>)
}

export const Disclaimer = () => {
    return (<>By using Just Trade It, you acknowledge that you have read, understood, and agreed to our <Link to="/terms">Terms of Use</Link>. If you do not agree with these terms, please do not use our services. Your use of our website and services constitutes your acceptance of these terms.</>)
}

export const TradingBookDescription = () => {
    return (<>
        <b>The trading book</b> allows to <b>track your trades</b> and determine the <b>percentage of gain/loss for each sell order</b>.
        All orders are <b>marked</b> on his related chart, buy orders are represented with <IconBuyOrder /> icon and sell orders are represnented with <IconSellOrder /> icon.
        The shares left not sold represent your <b>holdings</b>.
    </>)
}

// AUTHORIZATION //

export const ConsiderUpgrade = <>Consider <Link to="/pricing">upgrading</Link> for more features.</>
export const MaxItemsWatchlist = 'You have reached the maximun number of items in your watchlist for your current plan.'
export const MaxSymbolsViewed = 'You have reached the maximum number of different AI chart viewed per month available in your current plan.'
export const UnauthorizedTradingProfile = 'This Trading Profile is not available in your current plan.'
export const UnauthorizedCandleProMode = 'The candlestick chart pro mode is not available in your current plan.'
export const UnauthorizedAGIEarningsSummary = 'The AI earnings summary is not available in your current plan.'
export const UnauthorizedFinancialGraph = 'The Financial Graph is not available in your current plan.'

// OTHERS //

export const InfoArkInvest = <><Link to="https://www.ark-invest.com" target="_blank"><b>ARK Invest</b></Link> is an investment management firm. The dots represent when the firm has invested in the current asset.</>