import { Layout } from 'antd';
import React, { useState } from "react";
import LeftMenu from '../components/LeftMenu.jsx';
import IndexesView from '../components/IndexesView.jsx';
import FinancialGraph from '../components/FinancialGraph.jsx';
import JTICalendar from '../components/JTICalendar.jsx';
import MetaTags from '../MetaTags.js';

const { Content } = Layout;


const MacroView = () => {

    const components = {
        'IndexesView': <IndexesView />,
        'Calendar': <JTICalendar />,
        'FinancialGraph': <FinancialGraph />
    }
    const [selectedComponent, setSelectedComponent] = useState(components['IndexesView']);

    const menuItems = [
        { key: "IndexesView", label: "Indexes View" },
        { key: "Calendar", label: "Earnings Calendar" },
        { key: "FinancialGraph", label: "Financial Graph" },
    ]

    const handleSelectedMenu = (key) => {
        setSelectedComponent(components[key]);
    }

    return (
        <Layout className="site-layout-content">
            <MetaTags title="Just Trade It: Macro View" />

            <LeftMenu defaultKey='IndexesView' menuItems={menuItems} handleSelectedMenu={handleSelectedMenu} />

            <Content>
                {selectedComponent}
            </Content>
        </Layout>
    )
}

export default MacroView;