import { Image, Typography } from 'antd';
import React from 'react';
import MyBreadcrumb from '../../components/Breadcrumb';
import PageTitle1 from '../../components/PageTitle1';
import MetaTags from '../../MetaTags';
import '../../styles/Blog.css';
import { FAQ_CRUMB } from '../FAQ';
import { Link } from 'react-router-dom';
import { IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2 } from '../../components/Icons';
import PageTitle2 from '../../components/PageTitle2';


const { Paragraph } = Typography;

const title = "What are the Buy and Sell Signals and How to Use Them?"

const BuySellSignals = () => {

    return (<>
        <MetaTags
            title={title}
            description="Understand the JTI AI Model's Buy and Sell signals, how they work, and how to utilize them effectively. Learn about Level 1 and Level 2 signals for better trading decisions."
            keywords="trading buy signals, trading sell signals, buy signals, sell signals, trading signals, cluster signals, AI trading platform"
        />

        <MyBreadcrumb items={FAQ_CRUMB.concat([
            {
                title: title
            }
        ])} />

        <PageTitle1>• {title}</PageTitle1>

        <Paragraph className='blog-paragraph'>
            The JTI AI Model generates Buy and Sell signals to help traders identify the optimal times to enter or exit the market. These signals are based on an analysis of asset behavior, historical data, patterns, and market conditions such as support and resistance levels and volatility.
        </Paragraph>

        <Paragraph className='blog-paragraph'>
            The signals are categorized into two levels:
        </Paragraph>

        <ul className='blog-list'>
            <li>
                <strong><IconSignalBuyL1 /> Buy Signal Level 1 (L1): </strong>
                This indicates a good probability that the asset has reached the bottom of its current trend.
                Traders can consider this as a potential opportunity to enter the market.
            </li>
            <li>
                <strong><IconSignalBuyL2 /> Buy Signal Level 2 (L2): </strong>
                This represents a stronger indication that the asset has likely reached the bottom of its trend,
                signaling a higher-confidence buying opportunity.
            </li>
            <li>
                <strong><IconSignalSellL1 /> Sell Signal Level 1 (L1): </strong>
                Suggests that the asset may be nearing the top of its current trend, making it a potential time to consider selling.
            </li>
            <li>
                <strong><IconSignalSellL2 /> Sell Signal Level 2 (L2): </strong>
                A stronger signal indicating that the asset is likely at the top of its trend, suggesting a high-confidence selling opportunity.
            </li>
        </ul>

        <Paragraph className='blog-paragraph'>
            The signals should be used in combination with the win rate of the asset as analyzed by the JTI AI Model.
            For example, if an asset has a "High Win Rate" tag with a value of 80%, it means the model has historically demonstrated
            strong efficiency in identifying successful trade opportunities for that specific asset. A Buy Signal Level 2 (L2) on that asset,
            would mean that you have 8 out of 10 chance to make positive return buy bying at the signal level.
        </Paragraph>

        <PageTitle2>Cluster of Buy Signals</PageTitle2>

        <Paragraph className='blog-paragraph'>
            <Image width="100%" src="/faq_cluster_signals.png" alt="Cluster of buy signals" />
        </Paragraph>

        <Paragraph className='blog-paragraph'>
            Buy signal indicators can be used in combination with other buy signals, regardless of their level.
            When multiple buy signals form a cluster in the same region, this increases the probability of the trend reversing.
        </Paragraph>

        <Paragraph className='blog-paragraph'>
            These signals provide insights into market trends and are designed to help traders make informed decisions. However,
            they should not be taken as guaranteed outcomes, as market behavior is inherently unpredictable.
        </Paragraph>

        <Paragraph className='blog-paragraph'>
            To learn more, please visit: <Link to="/jti-ai-model" >JTI AI Model</Link>
        </Paragraph>
    </>)
}

export default BuySellSignals;