import { Layout } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/Blog.css';
import Error from './Error';
import JTIAIModel from './faq/JTIAIModel';
import JustTradeItBenefits from './faq/JustTradeItBenefits';
import TagMeaning from './faq/TagMeaning';
import WhatIsJustTradeIt from './faq/WhatIsJustTradeIt';
import BuySellSignals from './faq/BuySellSignals';


const components = {
    'what-is-just-trade-it': <WhatIsJustTradeIt />,
    'just-trade-it-benefits': <JustTradeItBenefits />,
    'buy-sell-signals': <BuySellSignals />,
    'tags-meaning': <TagMeaning />,
    'jti-ai-model': <JTIAIModel />,
}


const FAQPost = () => {

    const { urlPath } = useParams();

    return (
        <Layout className="site-layout-content-fixed site-layout-content-blog">

            {components[urlPath] ? components[urlPath] : <Error />}

        </Layout>
    )
};

export default FAQPost;