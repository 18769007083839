import { animated, useSpring, useSpringRef } from '@react-spring/web';
import { Carousel, Col, Row } from 'antd';
import { useInView } from 'react-intersection-observer';
import { Tweet } from 'react-tweet';
import { COLOR_PRIMARY } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Testimonial.module.css';
import { isMobile } from '../../../utils/utils';


// SHARED VARIABLES //
const IS_MOBILE = isMobile()


const Testimonial = () => {

    // SPRING EFFECTS //

    const [refTestimonials, inViewTestimonials] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? 0.15 : 0.4,
    })

    const springTestimonialsTitle = useSpring({
        config: { duration: 600 },
        from: { opacity: 0, },
        to: {
            opacity: inViewTestimonials ? 1 : 0,
        },
    })

    // CAROUSEL //

    const carouselData = [
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1855413779703419200',
        },
        {
            tweetId: '1855413779703419200',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
        {
            tweetId: '1849821172721938617',
        },
    ]

    // MOBILE
    var chunkSize = 3
    if (IS_MOBILE)
        chunkSize = 1

    // Group by chuncks
    const chunks = []
    for (let i = 0; i < carouselData.length; i += chunkSize) {
        chunks.push(carouselData.slice(i, i + chunkSize));
    }

    const carousel =
        <Carousel className={styles['carousel']} autoplay autoplaySpeed={3000} dotPosition="left" dots={{
            className: 'carousel-dots'
        }}>
            {chunks.map((chunk, idx1) => (
                <div key={idx1}>
                    <Row>
                        {chunk.map((item, idx2) => (
                            <Col key={idx2} xs={24} sm={24} md={24} lg={8} style={{ padding: '0 2rem 2rem 2rem' }}>
                                <div className={styles['tweet']} data-theme="light">
                                    <Tweet id={item.tweetId} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </Carousel>

    return (
        <div className="page" ref={refTestimonials}>
            <div className="landing-content">
                <Row justify="space-around">
                    <animated.div className='enrollement-title' style={springTestimonialsTitle}>
                        They Make <span style={{ color: COLOR_PRIMARY }}>Positive Return</span> With <span style={{ fontSize: '0.95em', border: '2px solid #000', color: COLOR_PRIMARY, padding: '0px 11px' }}>Just Trade It</span>
                    </animated.div>
                </Row>
                {carousel}
            </div>
            {/* <animated.div style={trailEnrollement[0]}>
                <Card>
                    <div className={styles['testimonial-author']}>
                        Micha - Cabin Crew Manager
                    </div>
                    <div className={styles['testimonial-text']}>
                        "I discovered  and started to use this tool named Just Trade It. It saves me a lot of time in my trading journey as it pre selects the stocks who are in the right ranges. Learned a lot with it. Thanks @jtradeit"
                    </div>
                    <div className={styles['testimonial-rate']}>
                        <Rate value={5} />
                    </div>
                </Card>
            </animated.div> */}
        </div>
    )
}

export default Testimonial;