import { BlockOutlined } from '@ant-design/icons';
import { Card, Space } from 'antd';
import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { init, getInstanceByDom } from 'echarts';
import { getTickerInfos } from "../services/ticker.service";
import { formatPourcentWithoutSign } from '../utils/utils'
import NoData from './NoData'
import { IconInfoHoldings } from './Icons';
import { getTickerDemoInfos } from '../services/demo.service';
import { CHART_COLOR_STYLES, CHART_LABEL_STYLE } from '../JTIConst';


// SHARED VARIABLES //


// PARAMETERS //

// Chart layout configurations
const CHART_CONFIG_LEFT = 1
const CHART_CONFIG_RIGHT = CHART_CONFIG_LEFT


const SankeyChart = ({ticker, tkrInfos, style, ...props}) => {

	var currentTicker = ticker
	if (!currentTicker)
		currentTicker = tkrInfos?.ticker

	const { getAccessTokenSilently } = useAuth0();

	const chartRef = useRef(null);
	const [noData, setNoData] = useState(true);
	const [chartLoading, setChartLoading] = useState(true);
	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {

		currentTicker = ticker
		if (!currentTicker)
			currentTicker = tkrInfos?.ticker

		const fetchData = async () => {
			setChartLoading(true)

			let tkrInfos = props.tkrInfos
			if (!tkrInfos) {
				if (props.isDemo) {
					const { data, error } = await getTickerDemoInfos(currentTicker);
					tkrInfos = data
				} else {
					const accessToken = await getAccessTokenSilently();
					const { data, error } = await getTickerInfos(accessToken, currentTicker);
					tkrInfos = data
				}
			}
			// console.log("Object:" + JSON.stringify(tkrInfos));

			// Crypto garde!
			if (tkrInfos.assetType === "CRYPTO")
				return;

			// Create the nodes and links
			const mainNodeName = '- ' + tkrInfos.shortName + ' -'	// Unique main node name to avoid duplicate node issue (RBC own RBC)
			const mainNodes = [{
				name: mainNodeName,
				label: {
					...CHART_LABEL_STYLE,
					position: 'top',
					distance: 30
				},
				itemStyle: {
					color: CHART_COLOR_STYLES[2],
				}
			}]

			if (tkrInfos.institutionalHolders != null) {

				// Use '.' to differenciate holders from holdings, otherwise give duplicate node issue.
				const holdersNodesLinks = tkrInfos.institutionalHolders.map(holder => ([
					{
						name: holder.holder + '.',
						itemStyle: {
							color: 'transparent',
							borderColor: CHART_COLOR_STYLES[1],
							borderWidth: 1.5
						}
					},
					{
						source: holder.holder + '.',
						target: mainNodeName,
						value: (holder.pctHeld * 100).toFixed(2),
						lineStyle: {
							color: CHART_COLOR_STYLES[1],
						}
					}
				]))
				const holdingsNodesLinks = tkrInfos.holdings?.map(holding => ([
					{
						name: '.' + holding.shortName,
						itemStyle: {
							color: 'transparent',
							borderColor: CHART_COLOR_STYLES[2],
							borderWidth: 1.5
						},
						label: {
							position: 'left'
						}
					},
					{
						source: mainNodeName,
						target: '.' + holding.shortName,
						value: formatPourcentWithoutSign(holding.percentOut),
						lineStyle: {
							color: CHART_COLOR_STYLES[2],
						},
					}
				]))

				const nodes = mainNodes.concat(holdersNodesLinks.map(([node]) => node)).concat(holdingsNodesLinks.map(([node]) => node))
				const links = holdersNodesLinks.map(([_, link]) => link).concat(holdingsNodesLinks.map(([_, link]) => link))
				// console.log("nodes:" + JSON.stringify(nodes))
				// console.log("links:" + JSON.stringify(links))

				// Managed case where SUM of all links value = 0 > Show no data

				// Calculate the sum of "value" properties
				const sumLinksValue = links.reduce((accumulator, currentValue) => {
					// Convert the "value" property to a number and add it to the accumulator
					return accumulator + parseFloat(currentValue.value);
				}, 0);

				if (sumLinksValue == 0) {
					setNoData(true)
				} else {
					setNoData(false)

					// Chart Options
					setChartOptions({
						backgroundColor: '#FFF',
						series: [
							{
								type: 'sankey',
								left: CHART_CONFIG_LEFT + '%',
								right: holdingsNodesLinks.length === 0 ? (CHART_CONFIG_RIGHT + 2) + '%' : CHART_CONFIG_RIGHT + '%',
								lineStyle: {
									// Apply to all links
									opacity: 0.1
								},
								emphasis: {
									focus: 'adjacency'
								},
								edgeLabel: {
									show: true,
									color: 'black',
									formatter: '{c}%'
								},
								data: nodes,
								links: links,
							}
						],
						tooltip: {
							trigger: 'item'
						}
					})
				}
			} else {
				setNoData(true)
			}
		}
		fetchData()

		setChartLoading(false)
	}, [ticker, tkrInfos])

	useEffect(() => {
		var chart = null;
		if (chartRef.current !== null && !noData) {        // Set by first return with no useEffect
			chart = init(chartRef.current);
			chartLoading === true ? chart.showLoading() : chart.hideLoading();
		}

		// Add chart resize listener, ResizeObserver is leading to a bit janky UX
		function resizeChart() {
			chart?.resize();
		}
		window.addEventListener("resize", resizeChart);

		// Return cleanup function
		return () => {
			chart?.dispose();
			window.removeEventListener("resize", resizeChart);
		};
	}, [chartLoading, noData])
	// TODO : Error without noData (reproduce: select symbol with no data then symbol with data)
	// chartLoading doesn't trigger I don't know why?

	useEffect(() => {
		// Update chart
		if (chartRef.current !== null && !noData) {
			const chart = getInstanceByDom(chartRef.current);
			chart.setOption(chartOptions);
		}
	}, [chartOptions])


	return (
		<Card id='holdings' title={<Space><BlockOutlined />Holders & Holdings</Space>} className='card card-chart' style={style} extra={<IconInfoHoldings />}>
			{noData ? (
				<NoData />
			) : (
				<div ref={chartRef} style={{ height: "600px" }} />
			)}
		</Card>
	)
}

export default SankeyChart;
